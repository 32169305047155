.editor {
  box-sizing: border-box;
  min-height: 15rem;
  border: 0px solid #ddd;
  cursor: text;
  padding: 8px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 0px 0px -3px #ABABAB;
  background: #fefefe;
}



.options {
  margin-bottom: 20px;
}

.file {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 6em;
  outline: none;
  visibility: hidden;
  cursor: copy;
  background-color: #c61c23;
  box-shadow: 0 0 15px solid currentColor;
}

.file:before {
  content: attr(data-title);
  color: rgb(30, 100, 57);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 6em;
  line-height: 2em;
  padding-top: 1.5em;
  opacity: 1;
  visibility: visible;
  text-align: center;
  border: 0.15em dashed rgb(30, 100, 57);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  overflow: hidden;
}

.file:hover {
  border-style: solid;
  box-shadow: inset 0px 0px 0px 1px currentColor;
}

.btnlink {
  color: #3F3F3F;
  font-style: normal;
  text-decoration: none;
}

.linkhead {
  min-height: 3rem;
  margin-top: 1.5rem;
}

.footer {
  flex: 1;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 16rem;
}

.hr {
  width: 25%;
  height: 0.2rem;
  background-color: #2487ce;
  opacity: 1;
  color: #2487ce
}

@media (max-width: 999px) {
  .hr {
    width: 100%;
  }
}

@media (min-width: 1000px) {
  .hr {
    width: 25%;
  }
}

.App {
  font-family: sans-serif;
}

.header {
  text-align: center;
}

.carouselcontainer {
  margin: 20px;
}

.selectedView {
  margin-bottom: 1em;
}

.selectedView:hover {
  cursor: pointer;
}

.selectedImage {
  width: 100%;
  margin-bottom: 8px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel {
  position: relative;
}

.carousel__images {
  display: flex;
  max-width: 100%;
  overflow-x: hidden;
}

.imageselected {
  margin-right: 10px;
  border: 2px solid #446fff !important;
  border-radius: 10px;
}

.carousel__image {
  margin-right: 10px;
  min-width: 100px;
  border: 3px solid #ffa70000;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel__button {
  position: absolute;
  top: 40%;
}

.carousel__button_left {
  position: absolute;
  top: 30%;
  left: 10px;
}

.carousel__button_right {
  position: absolute;
  top: 30%;
  right: 10px;
}

.carousel__button_left:hover {
  position: absolute;
  background-color: black;
  opacity: 0.8;
}

.carousel__button_right:hover {
  position: absolute;
  background-color: black;
  opacity: 0.8;
}

.link_t {
  margin-right: 1rem;
  margin-left: 1rem;

}

.hrstyle {
  position: absolute !important;
  display: block;
}

;

.hrstyle::before {
  position: relative !important;
}


.editor {
  min-height: 140px;
}

.editor .justify {
  text-align: justify !important;
}

.editor figure {
  margin: 0 !important;
}

.editor img {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  z-index: 1 !important;
}

.editor figure div {
  width: 100% !important;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.css-advny6-MuiPopper-root{
  z-index: 200 !important;
}

.editor li {
  margin-left: 2rem !important;
}

.editor iframe {
  width: 55vw !important;
  height: 30vw !important;
}
