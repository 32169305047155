/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
    display: inline-block;
    margin-left: 15px;
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #48bad6;
    color: #48bad6;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #48bad6;
    color: #48bad6;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #48bad6;
    color: #48bad6;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dot-flashing {
    0% {
      background-color: rgb(72, 186, 214);
    }
    50%, 100% {
      background-color: rgba(72, 186, 214, 0.2);
    }
  }
/**
 * ==============================================
 * END Dot Flashing
 * ==============================================
 */