@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap');

table {
    border-collapse: separate;
    border-spacing: 0.4vw 0.5vh;
    margin-left: auto;
    font-family: monospace;
}

.tableLeyenda > tbody > tr > td {
    padding: 0.2vw;
    border-radius: 0.5vw;
}

.tableLeyenda > tbody > tr > td:last-child {
    min-width: 3.5vw;
}

.tableLeyenda > tbody > tr > td:nth-child(2) {
    text-align: left;
    padding-left: 0.8vw;
    padding-right: 0.8vw;
}

.tableLeyenda > tbody > tr:nth-child(even) > td:nth-child(2), .tableLeyenda > tbody > tr:nth-child(even) > td:nth-child(3){
    background: #30333c
}

.tableLeyenda > tbody > tr:nth-child(odd) > td:nth-child(2), .tableLeyenda > tbody > tr:nth-child(odd) > td:nth-child(3) {
    background: #5c5f66
}

.leyenda {
    position: absolute;
    top: 5.3vh;
    right: 0.5vw;
    width: 17vw;
    height: 26vh;
    overflow:hidden;
    color: #ffffff;
    font-size: min(2vw, 1.6vh);
    text-align: center;
}

.congresistaName {
    position: absolute;
    width: 100%;
    bottom: 5%;
    font-stretch: condensed;
    min-height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
}

.votePoint {    
    width: 30%;
    height: 30%;
    position: absolute;
    left: 4%;                            
    top: 4%;
    border-radius: 50%;
    
}

.cardDiagrama {    
    position: fixed;
    border-style: solid;
    border-width: 0.15vw;
}

.diagramaBackground {
    background: #000000;
    background: linear-gradient(to right bottom, hsl(270, 50%, 8%), transparent 66%), linear-gradient(to left bottom, hsl(270, 50%, 8%), transparent 66%), linear-gradient(to bottom, transparent, hsl(270, 50%, 8%) 30%), url(/public/images/background.jpg) no-repeat;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size:100%;
    background-position: top;
    padding: 0;
}

.congresistasConectados {    
    background: #5c5f66;
    padding: 0.4vw;
    border-radius: 0.5vw;
}

.logoContainer {
    position: absolute;
    width: 14vw;
    height: 24vh;
    top: 3%;
    left: 1%;
    overflow: hidden;
}

.logoContainer > img {
    max-height: 100%;
}

.bgcurules {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    padding-top: 7.7vh;
    padding-bottom: 2.3vh;
    padding-left: 1vw;
    padding-right: 1vw;
    opacity: 0.7;
}

.circleResults {
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 50%;
    border: solid 1px #ffffff;
    opacity: 0.8;
}

.select-votacion {
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 5vh;

    opacity: 0;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;

    text-indent: 1px;
    text-overflow: '';
}

.select-votacion option {
    margin: 40px;
    background: #140a1e;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.main-title {
	font-weight: 700;
	font-size: 1.5vw;
	letter-spacing: 0.15vw;
	color: #afafb6;
    font-family: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.congresistas-conectados-number {
	color: #48bad6;
	font-size: 3vw;
    font-weight: 700;
    font-family: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.div-votacion-title {
    position: absolute;
    top: 1vh;
    left: 15vw;
    width: 70vw;
    height: 5vh;
    
    color: #afafb6;
    font-size: 1.7vh;
    font-family: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    overflow: hidden;

    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
}

.div-fecha {
    position: absolute;
    bottom: 1.5vh;
    left: 1.5vw;
    font-size: 1.3vw;
    color: #afafb6;
    letter-spacing: 0.05vw;
    font-family: 'Open Sans', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.div-hora {
    position: absolute;
    bottom: 1.5vh;
    right: 1.5vw;     
    font-size: 1.3vw;
    color: #afafb6;
    font-family: monospace;
}

.lh-5 {    
    line-height: 5vh;
}

.fs-2vw {
    font-size: 2vw;
}