.selectFloat {
    position: fixed;
    top: 0px;
    right: 0px;
    background: none;
    color: #FFF;
    z-index: 99999;
    border: none;
    padding: 20px 25px;
}

.flexdColumn {
    flex-direction: column;
}

.cardBorder {
    border-width: 2px;
    margin-left: 2px !important;
    margin-right: 2px !important;
    font-size: 0.85vw;
    line-height: 0.8vw;
    font-weight: 900;
    letter-spacing: 0;
    border: solid;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    max-width: 20vw;
}

.cardNumber {
    font-size: 3vw;
    line-height: 7vh;
    color: rgba(0,0,0,0.85);
}

.cardBody {
    padding: 2px;
    min-height: 7.5vh;
}

.cardTitle{    
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.cardPaddin {
    margin-left: -12px;
    margin-right: -12px;
}

.cardPaddinCentroVotacion{
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
}
.candidatoSelected {
    /*border-color: #5ce516;*/
    background-color: #5ce516; /*5ce516*/ /*#4afff7*/
    
}



.cardX {
    /*
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0px;
    padding: 5px;
    font-size: 4vw;
    line-height: 4vw;
    color: rgba(0,0,0,1);*/
    /*color: #5ce516;*/

    font-size: 4vw;
    line-height: 7vh;
    color: rgba(0,0,0,0.85);
}

.cardLine {
    border-bottom: solid;
}


.candidatoDisabled {
    opacity: 0.2;
}

.topBarCongresista{
    display: flex;
    background-color: #1976d2;
    min-height: 64px;
    padding-left: 24px;
    padding-right: 24px;
    color: #ffffff;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    align-items: center!important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.gridContainer {
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto;
    background-color: #2196F3;
    padding: 10px;
}

.gridItem {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 15px;
    font-size: 30px;
    text-align: center;
  }

  .gridImage{
    max-width: 100%;
    height: auto;
  }

  .diagramaBackground {
    background-image: url('../imgs/mapa-votacion.jpg');
    background-color: #656565;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
  }

  .diagramaCompletoBackground {
    background-image: url('../imgs/diagrama-completo-4.jpg');
    background-color: #656565;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
  }

  .diagramaCompletoBackgroundTransparente {
    background-image: url('../imgs/diagrama-completo-transparente.png');
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size:100% 100%;
    background-position: center;
  }

  .votacionElementCompleto {
    position: fixed;
    cursor: crosshair;
    border-style: solid;
    border-width: 2px;
  }