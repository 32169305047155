.draftLeft :global(.public-DraftStyleDefault-ltr) {
  text-align: left;
}

.draftRight :global(.public-DraftStyleDefault-ltr) {
  text-align: right;
}

.draftCenter :global(.public-DraftStyleDefault-ltr) {
  text-align: center;
}

.draftJustify :global(.public-DraftStyleDefault-ltr) {
  text-align: justify;
}

